<template>
  <!-- 售票结算弹窗确认单 -->
  <div class="ticketPopup">
    <div class="ticketPopup-box">
      <div class="header">
        <div class="text">结算单</div>
        <div class="close" @click="closeFun()"></div>
      </div>
      <div id="content" ref="content" class="content">
        <h1>
          {{
            rowDataDetail.businessType === 1
              ? "定制客运确认单"
              : rowDataDetail.businessType === 2
              ? "汽车票确认订单"
              : rowDataDetail.businessType === 4
              ? "包车确认单"
              : rowDataDetail.businessType === 5
              ? "租车确认单"
              : rowDataDetail.businessType === 6
              ? "定制班线确认单"
              : ""
          }}
        </h1>
        <div class="canvas-mask"></div>
        <canvas
          v-if="show"
          id="myCanvas"
          ref="myCanvas"
          width="130px"
          height="120px"
          class="canvas"
        ></canvas>
        <Table
          :table-data="tableData"
          :resizable="false"
          :title-name="titleName"
        ></Table>
        <div class="table-repair">
          <div class="table-repair-remarks">备注：</div>
          <div class="table-repair-agent">
            <div class="agent-left">
              <div class="top">经办人：{{ agent }}</div>
              <div class="bottom">单位：{{ company }}</div>
            </div>
            <div class="agent-right">
              <div class="top">经办人：{{ agent2 }}</div>
              <div v-if="show" class="bottom">
                单位： 四川神马出行网络科技有限责任公司
              </div>
              <div v-else class="bottom">
                单位：
                {{
                  channelNo == "KK_XCX_KKX"
                    ? "四川英海瑞特科技有限公司"
                    : "沐川县巴适到家汽车运输有限公司"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <!-- <el-button @click="PrintingFun()" v-print="'#content'">打印</el-button> -->
        <el-button @click="closeFun()">取消</el-button>
        <el-button @click="downloadPdf">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import imgs from "../../../../assets/images/home/officialSeal.png";
import DomToImage from "dom-to-image";
import jspdf from "jspdf";
import host from "@/api/static/host";
export default {
  props: ["rowDataDetail", "company", "agent", "agent2"],
  data() {
    return {
      channelNo: host.channelNo,
      tableData: [],
      titleName: [
        {
          title: "车站名称",
          children: [
            {
              title: "结算周期",
              props: "statisticsMonth",
              width: 200,
            },
          ],
        },
        {
          title: this.rowDataDetail.companyName,
          children: [
            {
              title: "预定数量",
              props: "totalOrderNum",
            },
            {
              title: "预定金额",
              props: "totalOrderAmount",
            },
            {
              title: "退/改数",
              props: "refundChangeNum",
            },
            {
              title: "退/改金额",
              props: "refundChangeAmount",
            },
            {
              title: "退/改手续费",
              props: "refundChangeServiceAmount",
              width: 100,
            },
            {
              title: "售后退款",
              props: "salesRefundAmount",
            },
            {
              title: "营销优惠金额",
              props: "preferentialPrices",
              width: 110,
            },
            {
              title: "订单收支净额",
              props: "netAmount",
              width: 110,
            },
          ],
        },
      ],
      show: true,
    };
  },
  methods: {
    // 生成pdf
    downloadPdf() {
      console.log(this.rowDataDetail);
      let names =
        this.rowDataDetail.businessType === 1
          ? "定制客运确认单_"
          : this.rowDataDetail.businessType === 2
          ? "汽车票确认订单_"
          : this.rowDataDetail.businessType === 4
          ? "包车确认单_"
          : this.rowDataDetail.businessType === 5
          ? "租车确认单_"
          : this.rowDataDetail.businessType === 6
          ? "定制班线确认单_"
          : "";
      let target = this.$refs.content;
      let that = this;
      DomToImage.toPng(document.getElementById("content"), {
        quality: 1.0,
      }).then(function (dataUrl) {
        const PDF = new jspdf("", "pt", "a4");
        var contentWidth = target.offsetWidth;
        var contentHeight = target.offsetHeight;
        const imgWidth = 595.28;
        const imgHeight = (592.28 / contentWidth) * contentHeight;
        PDF.addImage(dataUrl, "JPEG", 0, 0, imgWidth, imgHeight);
        PDF.save(names + that.tableData[0].month + ".pdf");
      });
    },
    // 关闭弹窗方法
    closeFun() {
      this.$emit("closeFun");
    },
  },
  created() {
    if (host.channelNo != "KK_XCX_GYSM") this.show = false;
    this.tableData.push(this.rowDataDetail);
  },
  mounted() {
    var canvas = document.getElementById("myCanvas");
    var ctx = canvas.getContext("2d");
    var img = new Image();
    img.src = imgs;
    //以Canvas画布上的坐标(10,10)为起始点，绘制图像
    img.onload = function () {
      ctx.drawImage(img, 0, 0, 130, 120);
    };
  },
};
</script>

<style lang="scss" scoped>
.canvas-mask {
  background: transparent;
  position: absolute;
  z-index: 890;
  left: 606px;
  top: 320px;
  transform: rotate(-2deg);
  width: 130px;
  height: 120px;
}
.canvas {
  background: rgba(255, 255, 255, 0);
  position: absolute;
  z-index: 888;
  left: 606px;
  top: 320px;
  transform: rotate(-2deg);
}
.ticketPopup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 777;
  .ticketPopup-box {
    position: absolute;
    width: 1000px;
    height: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 6px;
    overflow: hidden;
  }
  .header {
    padding: 18px 24px;
    height: 24px;
    position: absolute;
    left: 0;
    width: calc(100% - 48px);
    top: 0;
    border-bottom: 1px solid #d7d7d7;
    .text {
      margin-top: 8px;
    }
    .close {
      cursor: pointer;
      width: 12px;
      height: 12px;
      background: url("../../../../assets/images/home/close.png") no-repeat;
      background-size: 100%;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .content {
    position: absolute;
    top: 60px;
    left: 0;
    height: calc(520px - 12px);
    width: calc(100% - 40px);
    padding: 6px 20px;
    h1 {
      text-align: center;
      width: 100%;
    }
    .table-repair {
      width: calc(100% - 2px);
      height: 266px;
      .table-repair-remarks {
        width: calc(100% - 36px);
        height: 50px;
        border-bottom: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
        padding: 10px 18px;
      }
      .table-repair-agent {
        width: 100%;
        height: calc(100% - 70px);
        display: flex;
        border-bottom: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
        .agent-left {
          width: 50%;
          .top,
          .bottom {
            height: calc(50% - 36px);
            line-height: 80px;
            width: calc(100% - 36px);
            padding: 18px;
          }
        }
        .agent-right {
          width: 50%;
          .top,
          .bottom {
            height: calc(50% - 36px);
            line-height: 80px;
            width: calc(100% - 36px);
            padding: 18px;
          }
        }
      }
    }
  }
  .footer {
    padding: 10px 24px 18px 24px;
    height: 32px;
    position: absolute;
    left: 0;
    width: calc(100% - 48px);
    border-bottom: none !important;
    border-top: 1px solid #d7d7d7;
    bottom: 0 !important;
    .el-button {
      float: right;
      margin-left: 14px;
    }
  }
}
</style>
